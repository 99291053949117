export const MenuItems = [
    {
//        title: 'Home',
//        url: '/',
//        cName: 'nav-links'
//    },
//    {
//        title: 'Work',
//        url: '/work',
//        cName: 'nav-links'
//    },
//    {
//        title: 'About',
//        url: '/about',
//        cName: 'nav-links'
    }
]