import React from 'react'
import './intro.css'
export default function Intro() {
    return (
        <div className="container-card">
           <p className="intro-p" >
                👨🛠📋  I'm an aspiring engineer and self-taught programmer. I'm always fascinated by solving problems through technology maybe that brought me to choose this carrier path.
           </p>
            <p className="intro-p" >
               
                I’m looking forward to advance my skills and obtain a challenging position		 		          Bouddha, kathmandu
in a software development organization that gives me scope to apply my knowledge and skills and be a member of a team that  dynamically work towards success and growth of the organization.	 

           </p>
            <p className="intro-p" >
                 My hobbies include travelling and hiking outdoors.
           </p>
        </div>
    )
}
