import React from 'react'
import './Slogan-styles.css'
export default function Slogan() {
    return (
        <div class="aboutme css-typing">
            <p className="hi">
            Hi, I am
            </p>
            <p className="name">
             Shneha Paudyal
            </p>
            <p className="name">
              Software Quality Assurance Analyst
            </p>
        </div>
    )
}
